import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import commonHelper from '../helpers/commonHelper';
import AwesomeContent from './AwesomeContent.js';


function Awesome() {
  const naviagte = useNavigate();
  const { id, language } = useParams();



  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encodedData = searchParams.get('data');

  // Decode the encoded data
  const decodedData = JSON.parse(decodeURIComponent(encodedData));

  // const { shipperSize,navigateTopage,primaryPack} =  decodedData
  const { navigateTopage, primaryPack } = decodedData
  const packSize = 10;

  const extractNumber = (str) => {
    const match = str.match(/\d+/);
    return match ? Number(match[0]) : null;
  };

  // const shipperSizeNumber = extractNumber(shipperSize); 
  const packSizeNumber = extractNumber(primaryPack);


  // let valueInlitre = packSize - shipperSizeNumber ;
  let valueInlitre = packSize - packSizeNumber;

  const translations = commonHelper.getTransOfLanguage(language);


  useEffect(() => {
    if (valueInlitre > 0 && valueInlitre < 11 && packSizeNumber < 11) {
      const timeoutId = setTimeout(() => {
        // const data = {
        //   shipperSize :shipperSizeNumber  ,
        //   navigateTopage: navigateTopage ? "/congratulation" :  false
        // };
        const data = {
          primaryPack: packSizeNumber,
          navigateTopage: navigateTopage ? "/congratulation" : false
        };
        const encodedData = encodeURIComponent(JSON.stringify(data));
        naviagte(`/upiDetails/${id}/${language}?data=${encodedData}`)
      }, 5000)

      return () => {
        clearTimeout(timeoutId);

      };
    }
  }, [])

  if (valueInlitre > 0 && valueInlitre < 11 && packSizeNumber < 11) {

    return (
      <main class="sweep-thnku farmer_landing f-awesome">
        <section class="thnk-page">
          <img src="/images/farmer_bg1.png" class="img-fluid sweep-thnku-bg" alt="" />
          <div class="welcome-content">
            <img src={translations?.awesome_img} class="img-fluid sweep-logo" alt="" />
            <div class="sweep-thnku-text">

              {translations && packSizeNumber && valueInlitre &&
                <AwesomeContent
                  translations={translations}
                  packSizeNumber={packSizeNumber}
                  valueInlitre={valueInlitre}
                  language={language}
                />}

              <img src="/images/stars.png" class="img-fluid stars" alt="" />
            </div>


          </div>
        </section>
      </main>
    )

  }

  return (
    <main class="sweep-thnku farmer_landing f-awesome">
      <section class="thnk-page">
        <img src="/images/farmer_bg1.png" class="img-fluid sweep-thnku-bg" alt="" />
        <div class="welcome-content">
          <img src="/images/awesome.png" class="img-fluid sweep-logo" alt="" />
          <div class="sweep-thnku-text">

            <ul>
              <li>
                <h4 class="middle_heading">{translations?.awesome_else_heading_top}</h4>
                {/* <p class="green_middle">3 litres</p> */}
                <p class="green_middle"> {packSizeNumber && packSizeNumber +" "+ translations?.awesome_liter}</p>

              </li>
              <li>
                <h4 class="middle_heading">{translations?.awesome_else_heading_middle}</h4>
              </li>
              <li>
                <button className="btn btn-success sweep-submit" onClick={() => naviagte(`/scan/${id}/${language}`)} >{translations?.scan_more}</button>
              </li>
              <li>
                <button className="btn btn-success sweep-submit" onClick={() => naviagte(`/upiDetails/${id}/${language}`)} >{translations?.next}</button>
              </li>
            </ul>
            <img src="/images/stars.png" class="img-fluid stars" alt="" />
          </div>


        </div>
      </section>
    </main>
  )
}

export default Awesome