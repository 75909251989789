import React, { useEffect } from 'react'
import confetti from 'canvas-confetti';
import {  useParams } from 'react-router-dom';
import commonHelper from '../helpers/commonHelper';

function Thankyou() {
    const {  language } = useParams();

    const translations = commonHelper.getTransOfLanguage(language);

    useEffect(() => {
        makeItRain()
    }, [])

    const makeItRain = () => {
        const end = Date.now() + (2 * 500);
        const colors = ['#bb0000', '#ffffff'];

        const frame = () => {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;

            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: centerX / window.innerWidth, y: centerY / window.innerHeight },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: centerX / window.innerWidth, y: centerY / window.innerHeight },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        };

        frame();
    };

    return (
        <main class="sweep-thnku">
            {[...Array(44).keys()].map((_, index) => (
                <div key={index} className={`confetti-${index}`}></div>
            ))}

            <section class="thnk-page">
                <img src="/images/sweep-bg2.png" class="sweep-thnku-bg"  alt="sweep-bg2.png"/>
                <div class="thnku-content">
                    <img src="/images/logo.png" class="sweep-logo"  alt="log.png"/>
                    <div class="sweep-thnku-text">
                        <h1 class="sweep-welcome-heading">{translations?.thankyou}</h1>
                        <p class="sweep-welcome-para1" style={{marginBottom:"1rem"}}>{translations?.thankyou_sub}</p>
                        <p class="sweep-welcome-para1">{translations?.thankyou_sub_sec} </p>
                    </div>
                </div>

            </section>
        </main>
    )
}

export default Thankyou