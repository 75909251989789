import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import APIServicenew from "../utils/APIGeneralService";
import { ToastContainer, toast } from 'react-toastify';
import "../upiDetails.css"
import commonHelper from '../helpers/commonHelper';
import getValidationMessage from '../helpers/ValidationMessages';

const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

const UpiDetails = () => {
    const [loading,setLoading] = useState(false)
    const { id, language } = useParams();
    const { register, handleSubmit, formState: { errors },watch } = useForm();
    const naviagte = useNavigate();

    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const encodedData = searchParams.get('data');
    
    // Decode the encoded data
    // const decodedData =  encodedData ? JSON.parse(decodeURIComponent(encodedData)) : {};
    // const { navigateTopage} =  decodedData;
    const translations = commonHelper.getTransOfLanguage(language);

    const selectedUpiType = watch('upiType');

    const handleUpiDataSubmit = async (data) => {
        try {
            setLoading(true)
            const res = await APIServices.post(`distributor/addDetails/${id}`, data);
            if (res.status === 200) {
                if (res.data && res.data.status) {
                        naviagte(`/distributor/thankyou/${language}`)

                } else {
                    // setError(res?.data?.message || 'Something went wrong, please try after sometime.')
                    toast.error(res?.data?.message || 'Something went wrong, please try after sometime.', {
                        className: 'dark-toast'
                      });
                }
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            toast.error(error?.message || 'Something went wrong, please try after sometime.');
        }

    }

    return (
        <>
          <ToastContainer  theme="colored" />
      
        <main className="sweep-thnku">
            <section className="thnk-page">
                <img src="/images/sweep-bg.png" className="sweep-thnku-bg" alt="logo"  />
                <div className="welcome-content">
                    <img src="/images/logo.png" className="sweep-logo" alt="logo" />
                    <div className="sweep-thnku-text">
                        <h1 className="sweep-welcome-heading">{translations?.cashback_welcome_main}</h1>
                        <p className="sweep-welcome-para1">{translations?.cashback_welcome_sub}</p>
                    </div>
                    <form className="sweep-input-wrapper" onSubmit={handleSubmit(handleUpiDataSubmit)}>
                        <div className="sweep-gpay">
                            <p className="sweep-upi-para" style={{marginBottom:"0"}}>{translations?.upi_or_gpay_main}</p>
                            <p className="sweep-upi-para">{translations?.upi_or_gpay_sub}</p>
                            
                            <div className="sweep-input-wrapper">
                                <input type="text"
                                    {...register("upiValue", {
                                        required: true
                                    })}
                                    className="form-control"
                                    placeholder={selectedUpiType === 'gpay_number' ? translations?.input_gpay_no_text : translations?.input_upi_id_text } />
                                <select className="form-select" aria-label="Default select example"
                                    {...register("upiType", {
                                        required: true
                                    })}
                                >
                                    <option value="">- {translations?.upi_select}</option>
                                    <option value="upi_id">UPI ID</option>
                                    <option value="gpay_number">GPay No.</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    {errors.upiValue?.type === "required" && (
                                       <p className="text-danger">{  selectedUpiType === 'gpay_number' ?  getValidationMessage(language, 'required_gpay_number') : getValidationMessage(language, 'required_upi_id')}</p>
                                    )}
                                    

                                </div>
                                <div class="col-4">
                                    {errors.upiType?.type === "required" && (
                                         <p className="text-danger">{getValidationMessage(language, 'required_type')}</p>
                                    )}

                                </div>
                            </div>
                            <div class="row ">
                            {selectedUpiType === 'gpay_number' && (
                                        <>
                                            <div className="col-12 sweep-input-wrapper mt-2 justify-content-start">
                                                <input type="text" {...register('gpayName', { required: selectedUpiType === 'gpay_number' })} className="form-control" placeholder={translations?.input_gpay_name_text} />

                                            </div>
                                            <div class="col-8 justify-content-start">
                                                {errors.gpayName && <p className="text-danger">{getValidationMessage(language, 'required_gpay_name')}</p>}
                                            </div>
                                        </>
                            )}
                            </div>
                            <button 
                            // style={{ color: "white", backgroundColor: "#19875F" }}
                             className="btn btn-success sweep-submit" disabled={loading}>{loading? translations?.scan_loading : translations?.scan_submit}</button>
                          
                        </div>
                    </form>
                </div>
            </section>
        </main>
        </>
    )
}

export default UpiDetails;