import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import commonHelper from '../helpers/commonHelper.js';

function EarnCashback() {
  const { id, language } = useParams();
  const naviagte = useNavigate();

  const translations = commonHelper.getTransOfLanguage(language);
  
  return (
    <main className="sweep-thnku farmer_landing farmer-welcome">               
    <section className="thnk-page">
        <img src="/images/farmer_bg2.png" className="sweep-thnku-bg" alt=""/>
        <div className="thnku-content">
            <img src="/images/logo.png" className="sweep-logo" alt=""/>
            <div className="sweep-thnku-text">
               <h1 className="sweep-welcome-heading">{translations?.cashback_welcome_main}</h1>
                <h4 className="middle_heading">{translations?.cashback_welcome_sub}</h4>
            </div>

            <div className="cashback_btn">
               <a data-bs-toggle="offcanvas" data-bs-target="#scan_success"
               onClick={()=>naviagte(`/scan/${id}/${language}`)}> {translations?.cashback_btn}</a>
            </div>

        </div>

    </section>
</main>
  )
}

export default EarnCashback