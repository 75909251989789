import React from 'react'

function AwesomeContent({ translations, packSizeNumber, valueInlitre, language }) {
    switch (language) {
        case "english":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "punjabi":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "tamil":
            return (
                <ul>
                    <li>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                    </li>
                    <li>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                    </li>
                    <li>
                        {/* <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4> */}
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "telugu":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "marathi":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "gujrati":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "bengali":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "kannada":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )

        case "hindi":
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )    

        default:
            return (
                <ul>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_top}</h4>
                        <p class="green_middle"> {packSizeNumber && packSizeNumber + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading">{translations?.awesome_heading_middle}</h4>
                        <p class="green_middle">{packSizeNumber && valueInlitre + " " + translations?.awesome_liter}</p>
                    </li>
                    <li>
                        <h4 class="middle_heading" >{translations?.awesome_heading_middle_sec}</h4>
                        <p class="green_middle">{translations?.awesome_heading_end}</p>
                    </li>
                </ul>
            )
    }
}

export default AwesomeContent