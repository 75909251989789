const validationMsg = {
  english: {
    required_value: "Input value is required",
    required_type: "Type is required",
    required_gpay_name :"GPay name is required",
    required_gpay_number :"GPay number is required",
    required_upi_id :"UPI id is required",
    required_farmily_id :"farmily id is required",
    required_sfdc_code :"Sfdc code is required",
  },
  punjabi: {
    required_value: "ਇਨਪੁਟ ਮੁੱਲ ਦੀ ਲੋੜ ਹੈ",
    required_type: "ਕਿਸਮ ਦੀ ਲੋੜ ਹੈ",
    required_gpay_name :"GPay ਨਾਮ ਦੀ ਲੋੜ ਹੈ",
    required_gpay_number :"GPay ਨੰਬਰ ਦੀ ਲੋੜ ਹੈ",
    required_upi_id :"UPI ਆਈ.ਡੀ. ਦੀ ਲੋੜ ਹੈ",
    required_farmily_id :"farmily id ਲੋੜ ਹੈ",
    required_sfdc_code :"SFDC ਕੋਡ ਦੀ ਲੋੜ ਹੈ",
  },
  tamil: {
    required_value: "உள்ளீட்டு மதிப்பு தேவை",
    required_type: "வகை தேவை",
    required_gpay_name :"GPay பெயர் தேவை",
    required_gpay_number :"GPay எண் தேவை",
    required_upi_id :"UPI ஐடி தேவை",
    required_farmily_id :"farmily id தேவை",
    required_sfdc_code :"Sfdc குறியீடு தேவை",
  },
  telugu: {
    required_value: "ఇన్‌పుట్ విలువ అవసరం",
    required_type: "రకం అవసరం",
    required_gpay_name :"GPay పేరు అవసరం",
    required_gpay_number :"GPay నంబర్ అవసరం",
    required_upi_id :"UPI id అవసరం",
    required_farmily_id :"farmily id అవసరం",
    required_sfdc_code :"Sfdc కోడ్ అవసరం",
  },
  marathi: {
    required_value: "इनपुट मूल्य आवश्यक आहे",
    required_type: "प्रकार आवश्यक आहे",
    required_gpay_name :"GPay नाव आवश्यक आहे",
    required_gpay_number :"GPay नंबर आवश्यक आहे",
    required_upi_id :"UPI आयडी आवश्यक आहे",
    required_farmily_id :"farmily id आवश्यक आहे",
    required_sfdc_code :"Sfdc कोड आवश्यक आहे",
  },
  gujrati: {
    required_value: "ઇનપુટ મૂલ્ય આવશ્યક છે",
    required_type: "પ્રકાર જરૂરી છે",
    required_gpay_name :"GPay નામ જરૂરી છે",
    required_gpay_number :"GPay નંબર જરૂરી છે",
    required_upi_id :"UPI આઈડી જરૂરી છે",
    required_farmily_id :"farmily id જરૂરી છે",
    required_sfdc_code :"Sfdc કોડ જરૂરી છે",
  },
  bengali: {
    required_value: "ইনপুট মান প্রয়োজন",
    required_type: "প্রকার প্রয়োজন",
    required_gpay_name :"GPay নাম আবশ্যক",
    required_gpay_number :"GPay নম্বর প্রয়োজন",
    required_upi_id :"UPI আইডি প্রয়োজন",
    required_farmily_id :"farmily id আবশ্যক",
    required_sfdc_code :"Sfdc code কোড প্রয়োজন",
  },
  kannada: {
    required_value: "ಇನ್‌ಪುಟ್ ಮೌಲ್ಯದ ಅಗತ್ಯವಿದೆ",
    required_type: "ಪ್ರಕಾರದ ಅಗತ್ಯವಿದೆ",
    required_gpay_name :"GPay ಹೆಸರು ಅಗತ್ಯವಿದೆ",
    required_gpay_number :"GPay ಸಂಖ್ಯೆಯ ಅಗತ್ಯವಿದೆ",
    required_upi_id :"UPI ಐಡಿ ಅಗತ್ಯವಿದೆ",
    required_farmily_id :"farmily id ಅಗತ್ಯವಿದೆ",
    required_sfdc_code :"Sfdc code ಕೋಡ್ ಅಗತ್ಯವಿದೆ",
  },
  hindi: {
    required_value: "इनपुट मान आवश्यक है",
    required_type: "प्रकार आवश्यक है",
    required_gpay_name :"GPay नाम आवश्यक है",
    required_gpay_number :"GPay नंबर आवश्यक है",
    required_upi_id :"यूपीआई आईडी आवश्यक है",
    required_farmily_id :"farmily id आवश्यक है",
    required_sfdc_code :"Sfdc code कोड आवश्यक है",
  },

};

const getValidationMessage = (lang, type) => {
  return validationMsg[lang]?.[type] ? validationMsg[lang][type] : validationMsg['english'][type] ? validationMsg['english'][type] : "Invalid input";
};

export default getValidationMessage;
