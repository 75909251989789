const translations = {
  english: {
    cashback_welcome_main: "WELCOME",
    Congratulations: "Congratulations !",
    thankyou: "THANK YOU",
    cashback_welcome_sub: "to the SWEEP POWER Reward Program",
    cashback_btn: "CLICK HERE TO EARN CASHBACK",
    scan_or: "OR",
    scan_uid: "Enter UID no",
    scan_submit: "SUBMIT",
    scan_submit_succ: "UID SUBMITTED SUCCESSFUL",
    scan_succ: "SCAN SUCCESSFUL",
    scan_loading: "LOADING...",
    awesome_img: "/images/awesome.png",
    awesome_liter: "litres",
    awesome_heading_top: "You’ve scanned",
    awesome_heading_middle: "of Sweep Power. Just",
    awesome_heading_middle_sec: "more, and you can",
    awesome_heading_end: "WIN MEGA PRIZES !",
    upi_select: "Select",
    upi_or_gpay_main: "Please enter",
    upi_or_gpay_sub: "your UPI ID or GPay No to the cashback",
    congratu_img: "/images/congratulations.png",
    congratu_head: "You got ₹",
    congratu_head_sec: "cashback for scanning",
    congratu_head_sub: "liters SWEEP POWER",
    thankyou_sub: "Your reward",
    scan_more:"SCAN MORE",
    next:"NEXT",
    awesome_else_heading_top: "You’ve scanned",
    awesome_else_heading_middle: "of Sweep Power",
    thankyou_sub_sec: "will be credited to your account soon.",
    congratu_cashback_img: "/images/casback.png",
    whatsapp_navigate_btn :"Back to whatsapp",
    linkText:"I would like to be part of Sweep Power Reward Program",
    input_upi_id_text : "Enter UPI ID",
    input_gpay_no_text : "Enter GPay number" ,
    input_gpay_name_text : "Enter GPay name" ,
    input_farmily_id : "Enter farmily id" ,
    input_sfdc_code : "Enter SFDC code" ,

  },
  punjabi: {
    cashback_welcome_main: "ਆਇਆ ਨੂੰ",
    Congratulations: "ਮੁਬਾਰਕਾਂ !",
    thankyou: "ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
    cashback_welcome_sub: "ਸਵੀਪ ਪਾਵਰ ਰਿਵਾਰਡ ਪ੍ਰੋਗਰਾਮ ਵਿੱਚ ਤੁਹਾਡਾ ਜੀ  ",
    cashback_btn: "ਕੈਸ਼ਬੈਕ ਕਮਾਉਣ ਲਈ ਇੱਥੇ ਦੱਬੋ",
    scan_or: "ਜਾਂ",
    scan_uid: "ਯੂਆਈਡੀ ਨੰ",
    scan_submit: "ਜਮਾ ਕਰੋ",
    scan_submit_succ: "UID ਸਪੁਰਦ ਕੀਤਾ ਸਫਲ",
    scan_sub_succ: "ਯੂਆਈਡੀ ਸਫ਼ਲਤਾਪੂਰਵਕ ਸਬਮਿਟ",
    scan_succ: "ਸਕੈਨ ਸਫ਼ਲ",
    scan_loading: "ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ...",
    awesome_liter: "ਲੀਟਰ",
    awesome_heading_top: "ਤੁਸੀਂ",
    awesome_heading_middle: "ਸਵੀਪ ਪਾਵਰ ਸਕੈਨ ਕਰ ਲਿੱਤਾ ਹੈ । ਬਸ",
    awesome_heading_middle_sec: "ਹੋਰ, ਅਤੇ ਤੁਸੀਂ ",
    awesome_heading_end: "ਮੇਗਾ ਪ੍ਰਾਇਜ਼ ਜਿੱਤ ਸਕਦੇ ਹੋ !",
    upi_select: "ਚੁਣੋ",
    upi_or_gpay_main: "ਕਿਰਪਾ ਕੈਸ਼ਬੈਕ ",
    upi_or_gpay_sub: " ਲਈ ਆਪਣੀ ਯੂਪੀਆਈ ਆਈਡੀ ਜਾਂ ਜੀਪੇ ਨੰਬਰ ਦਰਜ ਕਰੋ",
    congratu_head: "ਤੁਹਾਨੂੰ ",
    congratu_head_sec: "ਸਵੀਪ ਪਾਵਰ ਸਕੈਨ ਕਰਨ ਤੇ ਲੀਟਰ",
    congratu_head_sub: "₹ ਕੈਸ਼ਬੈਕ ਮਿਲਿਆ",
    thankyou_sub: "ਤੁਹਾਡਾ ਰਿਵਾਰਡ ",
    thankyou_sub_sec: "ਜਲਦ ਹੀ ਤੁਹਾਡੇ ਖਾਤੇ ਵਿੱਚ ਜਮ੍ਹਾ ਕਰ ਦਿੱਤਾ ਜਾਵੇਗਾ ",
    scan_more:"ਹੋਰ ਸਕੈਨ ਕਰੋ",
    next:"ਅਗਲਾ",
    awesome_else_heading_top: "ਤੁਸੀਂ ਸਕੈਨ ਕੀਤਾ ਹੈ",
    awesome_else_heading_middle: "ਸਵੀਪ ਪਾਵਰ ਦਾ",
    awesome_img: "/images/Aewsome_punjabi.png",
    congratu_img: "/images/Congratulations_punjabi.png",
    congratu_cashback_img: "/images/Cashback_punjabi.png",
    whatsapp_navigate_btn :"ਵਾਟਸਐਪ ’ਤੇ ਵਾਪਸ ਜਾਓ",
    linkText:"ਮੈਂ ਸਵੀਪ ਪਾਵਰ ਰਿਵਾਰਡ ਪ੍ਰੋਗਰਾਮ ਦਾ ਹਿੱਸਾ ਬਣਨਾ ਚਾਹਾਂਗਾ",
    input_upi_id_text : "UPI ID ਦਾਖਲ ਕਰੋ",
    input_gpay_no_text : "GPay ਨੰਬਰ ਦਾਖਲ ਕਰੋ" ,
    input_gpay_name_text : "GPay ਨਾਮ ਦਾਖਲ ਕਰੋ"  ,
    input_farmily_id : "farmily id ਦਾਖਲ ਕਰੋ" ,
    input_sfdc_code : "SFDC ਕੋਡ ਦਾਖਲ ਕਰੋ" ,
  },
  tamil: {
    cashback_welcome_main: "வரவேற்பு",
    Congratulations: "வாழ்த்துக்கள் !",
    thankyou: "நன்றி",
    cashback_welcome_sub: "ஸ்வீப் பவர் வெகுமதி  திட்டத்திற்கு ற்கிறோம்",
    cashback_btn: "கேஷ்பேக் பெற இங்கே கிளிக் செய்யவும்",
    scan_or: "அல்லது",
    scan_uid: "யூஐடி எண்",
    scan_submit: "சமர்ப்பிக்கவும்",
    scan_submit_succ: "UID வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது",
    scan_succ: "ஸ்கேன் வெற்றி",
    scan_loading: "ஏற்றுகிறது...",
    awesome_liter: "லிட்டர்",
    awesome_heading_top: "ஸ்வீப் பவர்-ஐ நீங்கள் ஸ்கேன் செய்திருக்கிறீர்கள். இன்னும்",
    awesome_heading_middle: "செய்து விடுங்கள், மெகா ",
    awesome_heading_middle_sec: "",
    awesome_heading_end: "பரிசிசை நீங்கள் வெல்லலாம் !",
    upi_select: "தேர்ந்தெடு",
    upi_or_gpay_main: "கேஷ்பேக்கில்",
    upi_or_gpay_sub: "உங்கள் UPI ஐடி அல்லது GPay எண்ணை உள்ளிடவும்.",
    congratu_head: " லிட்டர் ஸ்வீப் பவர்-ஐ ஸ்கேன் செய்திருப்பதால் உங்களுக்கு",
    congratu_head_sec: "",
    congratu_head_sub: "₹ கேஷ்பேக் கிடைத்தது",
    thankyou_sub: "உங்கள் வெகுமதி",
    thankyou_sub_sec: "விரைவில் உங்கள் கணக்கில் வரவு வைக்கப்படும்.",
    scan_more:"மேலும் ஸ்கேன் செய்யவும்",
    next:"அடுத்தது",
    awesome_else_heading_top: "ஸ்கேன் செய்துள்ளீர்கள்",
    awesome_else_heading_middle: "ஸ்வீப் பவர்",
    awesome_img: "/images/Awesome_tamil.png",
    congratu_img: "/images/Congratulations_tamil.png",
    congratu_cashback_img: "/images/Cashback_tamil.png",
    linkText:"ஸ்வீப் பவர் வெகுமதி திட்டத்தில் சேர விரும்புகிறேன்",
    input_upi_id_text : "UPI ஐடியை உள்ளிடவும்",
    input_gpay_no_text : "GPay எண்ணை உள்ளிடவும்" ,
    input_gpay_name_text : "GPay பெயரை உள்ளிடவும்"  ,
    input_farmily_id : "farmily id ஐ உள்ளிடவும்" ,
    input_sfdc_code : "SFDC குறியீட்டை உள்ளிடவும்" ,
  },
  telugu: {
    cashback_welcome_main: "స్వాగతం",
    Congratulations: "అభినందనలు !",
    thankyou: "ధన్యవాదాలు.",
    cashback_welcome_sub: "స్వీప్‌ పవర్‌ రివార్డు  ప్రోగ్రామ్‌కి ",
    cashback_btn: "క్యాష్‌బ్యాక్‌ పొందడానికి ఇక్కడ క్లిక్‌ చేయండి",
    scan_or: "లేదా",
    scan_uid: "యుఐడి నం.",
    scan_submit: "సమర్పించండి",
    scan_submit_succ: "యుఐడి విజయవంతంగా సమర్పించడమైనది",
    scan_succ: "స్కాన్‌ విజయవంతమైంది",
    scan_loading: "లోడ్...",
    awesome_liter: "లీటర్లు",
    awesome_heading_top: "మీరు",
    awesome_heading_middle: "లీటర్‌ల స్వీప్‌ పవర్‌ని స్కాన్‌ చేశారు. కేవలం",
    awesome_heading_middle_sec: "లీటర్లు ఎక్కువగా చేయండి మరియు మీరు",
    awesome_heading_end: "మెగా బహుమతులు పొందవచ్చు !",
    upi_select: "ఎంచుకోండి",
    upi_or_gpay_main: "దయచేసి క్యాష్‌బ్యాక్‌కి",
    upi_or_gpay_sub: "మీ యుపిఐ ఐడి లేదా జీపే నం. నమోదు చేయండి",
    congratu_head: "మీరు ",
    congratu_head_sec: "లీటర్ల స్వీప్‌ పవర్‌ని స్కాన్‌ చేసినందుకు మీరు",
    congratu_head_sub: " ₹ క్యాష్‌బ్యాక్‌ పొందారు",
    thankyou_sub: "మీ రివార్డు",
    thankyou_sub_sec: "మీ అకౌంట్‌కి త్వరలోనే క్రెడిట్‌ చేయబడుతుంది.",
    scan_more:"మరింత స్కాన్ చేయండి",
    next:"తరువాత",
    awesome_else_heading_top: "మీరు స్కాన్ చేసారు",
    awesome_else_heading_middle: "స్వీప్ పవర్",
    awesome_img: "/images/Awesome_telugu.png",
    congratu_img: "/images/Congratulation_telugu.png",
    congratu_cashback_img: "/images/Cashback_telugu.png",
    linkText:"నేను స్వీప్ పవర్ రివార్డ్ ప్రోగ్రామ్‌లో భాగం కావాలనుకుంటున్నాను",
    input_upi_id_text : "UPI IDని నమోదు చేయండి",
    input_gpay_no_text : "GPay నంబర్‌ని నమోదు చేయండి" ,
    input_gpay_name_text : "GPay పేరును నమోదు చేయండి"  ,
    input_farmily_id : "farmily id ని నమోదు చేయండి" ,
    input_sfdc_code : "SFDC కోడ్‌ని నమోదు చేయండి" ,
  },
  marathi: {
    cashback_welcome_main: "स्वागत आहे",
    Congratulations: "अभिनंदन!",
    thankyou: "धन्यवाद",
    cashback_welcome_sub: "स्वीप पॉवर रीवॉर्ड प्रोग्रॅममध्ये आपले",
    cashback_btn: "कॅशबॅक कमावण्यासाठी येथे क्लिक करा",
    scan_or: "किंवा",
    scan_uid: "युआयडी क्र. टाका",
    scan_submit: "सुपूर्द करा",
    scan_submit_succ: "युआयडी यशस्वीपणे सुपूर्द झाला",
    scan_succ: "स्कॅन यशस्वी झाला",
    scan_loading: "लोड होत आहे...",
    awesome_liter: "लिटर",
    awesome_heading_top: "तुम्ही",
    awesome_heading_middle: "स्वीप पॉवर स्कॅन केलेत. आता आणखी फक्त",
    awesome_heading_middle_sec: "आणि तुम्ही जिंकू शकता ",
    awesome_heading_end: "मेगा बक्षिसे !",
    upi_select: "निवडा",
    upi_or_gpay_main: "कृपया कॅशबॅक",
    upi_or_gpay_sub: "मिळवण्यासाठी तुमचा युपीआय आयडी किंवा गूगलपे क्रमांक प्रविष्ट करा",
    congratu_head: "तुम्हाला स्वीप पॉवर चे",
    congratu_head_sec: "लीटर स्कॅन केल्याबद्दल",
    congratu_head_sub: "₹ कॅशबॅक मिळाला",
    thankyou_sub: "तुमचा रीवॉर्ड ",
    thankyou_sub_sec: "लवकरच तुमच्या खात्यात क्रेडिट होईल.",
    scan_more:"अधिक स्कॅन करा",
    next:"पुढे",
    awesome_else_heading_top: "तुम्ही स्कॅन केले आहे",
    awesome_else_heading_middle: "स्वीप पॉवर चे",
    awesome_img: "/images/Awesome_marathi.png",
    congratu_img: "/images/Congratulations_marathi.png",
    congratu_cashback_img: "/images/Cashback_marathi.png",
    linkText:"मला स्वीप पॉवर रिवॉर्ड प्रोग्रामचा भाग व्हायला आवडेल",
    input_upi_id_text : "UPI आयडी टाका",
    input_gpay_no_text : "GPay नंबर एंटर करा" ,
    input_gpay_name_text : "GPay नाव एंटर करा"  ,
    input_farmily_id : "farmily id प्रविष्ट करा" ,
    input_sfdc_code : "SFDC कोड प्रविष्ट करा" ,
  },
  gujrati: {
    cashback_welcome_main: "સ્વાગત છે",
    Congratulations: "અભિનંદન !",
    thankyou: "આભાર",
    cashback_welcome_sub: "સ્વીપ પાવર રિવોર્ડ પ્રોગ્રામમાં આપનું સ્વાગત છે",
    cashback_btn: "કૅશબૅક મેળવવા માટે અહીં ક્લિક કરો",
    scan_or: "અથવા",
    scan_uid: "UID નં.",
    scan_submit: "સબમિટ કરો",
    scan_submit_succ: "UID સફળતાપૂર્વક સબમિટ કર્યું",
    scan_succ: "સ્કૅન સફળ",
    scan_loading: "લોડ કરી રહ્યું છે...",
    awesome_liter: "લિટર",
    awesome_heading_top: "તમે સ્વીપ પાવરના",
    awesome_heading_middle: "સ્કૅન કર્યા છે. બસ",
    awesome_heading_middle_sec: "વધુ, અને તમે જીતી શકો છો",
    awesome_heading_end: "મેગા ઈનામો !",
    upi_select: "પસંદ કરો",
    upi_or_gpay_main: "કૃપા કરીને દાખલ કરો",
    upi_or_gpay_sub: "કૅશબૅક માટે તમારો UPI ID અથવા GPay નંબર.",
    congratu_head: "તમને સ્વીપ પાવરના",
    congratu_head_sec: "લિટર સ્કૅન કરવા બદલ ",
    congratu_head_sub: "₹ કૅશબૅક મળે છે",
    thankyou_sub: "તમારો પુરસ્કાર",
    thankyou_sub_sec: "ટૂંક સમયમાં તમારા ખાતામાં જમા થશે.",
    scan_more:"વધુ સ્કેન કરો",
    next:"આગળ",
    awesome_else_heading_top: "તમે સ્કેન કર્યું છે",
    awesome_else_heading_middle: "સ્વીપ પાવરનો",
    awesome_img: "/images/Awesome_gujrati.png",
    congratu_img: "/images/Congratulations_gujrati.png",
    congratu_cashback_img: "/images/Cashback_gujrati.png",
    linkText:"હું સ્વીપ પાવર રિવોર્ડ પ્રોગ્રામનો ભાગ બનવા માંગુ છું",
    input_upi_id_text : "UPI ID દાખલ કરો",
    input_gpay_no_text : "GPay નંબર દાખલ કરો" ,
    input_gpay_name_text : "GPay નામ દાખલ કરો"  ,
    input_farmily_id : "farmily id દાખલ કરો" ,
    input_sfdc_code : "SFDC કોડ દાખલ કરો" ,
  },
  bengali: {
    cashback_welcome_main: "স্বাগত",
    Congratulations: "অভিনন্দন!",
    thankyou: "ধন্যবাদ",
    cashback_welcome_sub: "সুইপ পাওয়ার রিওয়ার্ড প্রোগ্রামে",
    cashback_btn: "ক্যাশব্যাক পাওয়ার জন্য এখানে ক্লিক করুন",
    scan_or: "অথবা",
    scan_uid: "UID নং",
    scan_submit: "জমা দিন",
    scan_submit_succ: "UID সফলভাবে জমা দেওয়া হয়েছে",
    scan_succ: "স্ক্যান সফল হয়েছে",
    scan_loading: "লোড হচ্ছে...",
    awesome_liter: "লিটার",
    awesome_heading_top: "আপনি সুইপ পাওয়ার এর",
    awesome_heading_middle: "স্ক্যান করেছেন৷ আর মাত্র",
    awesome_heading_middle_sec: "আর আপনি মেগা পুরষ্কারগুলি ",
    awesome_heading_end: "জিততে পারেন৷ !",
    upi_select: "নির্বাচন করুন",
    upi_or_gpay_main: "ক্যাশব্যাক এর",
    upi_or_gpay_sub: "জন্য দয়া করে আপনার UPI ID অথবা GPay নং. দিন",
    congratu_head: "আপনি সুইপ পাওয়ার এর ",
    congratu_head_sec: "লিটার স্ক্যান করার জন্য",
    congratu_head_sub: "₹ ক্যাশব্যাক পেয়েছেন",
    thankyou_sub: "আপনার পুরষ্কার ",
    thankyou_sub_sec: "শীঘ্রই আপনার অ্যাকাউন্টে ক্রেডিট করা হবে৷",
    scan_more:"আরও স্ক্যান করুন",
    next:"পরবর্তী",
    awesome_else_heading_top: "আপনি স্ক্যান করেছেন",
    awesome_else_heading_middle: "সুইপ পাওয়ারের",
    awesome_img: "/images/Awesome_bengali.png",
    congratu_img: "/images/Congratulations_bengali.png",
    congratu_cashback_img: "/images/Cashback_bengali.png",
    linkText:"আমি স্যুইপ পাওয়ার রিওয়ার্ড প্রোগ্রামে অংশগ্রহণ করতে চাই",
    input_upi_id_text : "UPI আইডি লিখুন",
    input_gpay_no_text : "GPay নম্বর লিখুন" ,
    input_gpay_name_text : "GPay নাম লিখুন"  ,
    input_farmily_id : "farmily id লিখুন" ,
    input_sfdc_code : "Enter কোড লিখুন" ,
  },
  kannada: {
    cashback_welcome_main: "ಸ್ವಾಗತ",
    Congratulations: "అభినందనలు !",
    thankyou: "ధన్యవాదాలు",
    cashback_welcome_sub: "ಸ್ವೀಪ್ ಪವರ್ ರಿವಾರ್ಡ್ ಕಾರ್ಯಕ್ರಮಕ್ಕೆ ",
    cashback_btn: "ಕ್ಯಾಶ್ ಬ್ಯಾಕ್ ಗಳಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
    scan_or: "ಅಥವಾ",
    scan_uid: "ಯುಐಡಿ ಸಂ",
    scan_submit: "ಸಲ್ಲಿಸಿ",
    scan_submit_succ: "ಯುಐಡಿ ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
    scan_succ: "ಸ್ಕ್ಯಾನ್ ಯಶಸ್ವಿಯಾಗಿದೆ",
    scan_loading: "ಲೋಡ್ ಆಗುತ್ತಿದೆ...",
    awesome_liter: "ಲೀಟರ್",
    awesome_heading_top: "ನೀವು",
    awesome_heading_middle: "ಸ್ವೀಪ್ ಪವರ್ ಸ್ಕ್ಯಾನ್ ಮಾಡಿದ್ದೀರಿ. ಕೇವಲ",
    awesome_heading_middle_sec: "ಬಾಕಿ ಇದ್ದು, ನೀವು  ಗೆಲ್ಲಬಹುದು",
    awesome_heading_end: "ಮೆಗಾ ಬಹುಮಾನಗಳನ್ನು ",
    upi_select: "ಆಯ್ಕೆ ಮಾಡಿ",
    upi_or_gpay_main: "ಕ್ಯಾಶ್ ಬ್ಯಾಕ್ ",
    upi_or_gpay_sub: "ಗಾಗಿ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಯುಪಿಐ ಐಡಿ ಅಥವಾ ಜಿಪೇ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
    congratu_head: "ನೀವು ",
    congratu_head_sec: "ಲೀಟರ್ ಗಳ ಸ್ವೀಪ್ ಪವರ್ ಸ್ಕ್ಯಾನ್ ಮಾಡಿದ್ದಕ್ಕಾಗಿ ",
    congratu_head_sub: " ₹ ಕ್ಯಾಶ್ ಬ್ಯಾಕ್ ಗಳಿಸಿದ್ದೀರಿ ",
    thankyou_sub: "ನಿಮ್ಮ ರಿವಾರ್ಡ್ ",
    thankyou_sub_sec: "ಅನ್ನು ನಿಮ್ಮ ಖಾತೆಗೆ ಶೀಘ್ರವೇ ಜಮೆ ಮಾಡಲಾಗುತ್ತದೆ",
    scan_more:"ಇನ್ನಷ್ಟು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    next:"ಮುಂದೆ",
    awesome_else_heading_top: "ನೀವು ಸ್ಕ್ಯಾನ್ ಮಾಡಿದ್ದೀರಿ",
    awesome_else_heading_middle: "ಸ್ವೀಪ್ ಪವರ್ ನ",
    awesome_img: "/images/Awesome_kannada.png",
    congratu_img: "/images/Congratulations_kannada.png",
    congratu_cashback_img: "/images/Cashback_kannada.png",
    linkText:"ನಾನು ಸ್ವೀಪ್ ಪವರ್ ರಿವಾರ್ಡ್ ಪ್ರೋಗ್ರಾಂನ ಭಾಗವಾಗಲು ಬಯಸುತ್ತೇನೆ",
    input_upi_id_text : "UPI ಐಡಿ ನಮೂದಿಸಿ",
    input_gpay_no_text : "GPay ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ" ,
    input_gpay_name_text : "GPay ಹೆಸರನ್ನು ನಮೂದಿಸಿ"  ,
    input_farmily_id : "farmily id ನಮೂದಿಸಿ" ,
    input_sfdc_code : "SFDC ಕೋಡ್ ನಮೂದಿಸಿ" ,
  },
  hindi: {
    cashback_welcome_main: "स्वागत है",
    Congratulations: "बधाई हो !",
    thankyou: "धन्यवाद",
    cashback_welcome_sub: "स्वीप पावर रिवॉर्ड प्रोग्राम में आपका ।",
    cashback_btn: "कैशबैक कमाने के लिए यहां क्लिक करें",
    scan_or: "या",
    scan_uid: "यूआईडी नंबर दर्ज करें",
    scan_submit: "जमा करे",
    scan_submit_succ: "यूआईडी सफलतापूर्वक जमा किया गया",
    scan_succ: "स्कैन सफल",
    scan_loading: "लोड हो रहा है...",
    awesome_img: "/images/Awesome_hindi.png",
    awesome_liter: "लीटर",
    awesome_heading_top: "आपने आज",
    awesome_heading_middle: "स्वीप पावर स्कैन किया है।",
    awesome_heading_middle_sec: "और अधिक, और आप जीत सकते हैं",
    awesome_heading_end: "मेगा प्राइज !",
    upi_select: "चुनें",
    upi_or_gpay_main: "कृपया दर्ज करें",
    upi_or_gpay_sub: "कैशबैक के लिए आपकी UPI आईडी या GPay नंबर",
    congratu_img: "/images/Comngratulation_hindi.png",
    congratu_head: "आपने",
    congratu_head_sec: "लीटर स्वीप पावर स्कैन कर",
    congratu_head_sub: "कैशबैक रिवॉर्ड्स जीत लिया है",
    scan_more:"अधिक स्कैन करें",
    next:"जारी रखें",
    awesome_else_heading_top: "आपने आज",
    awesome_else_heading_middle: "स्वीप पावर स्कैन किया है।",
    thankyou_sub: "आपका नकद पुस्कार",
    thankyou_sub_sec: "जल्द ही आपके खाते में जमा कर दिया जाएगा।",
    congratu_cashback_img: "/images/Cashback_hindi.png",
    whatsapp_navigate_btn :"व्हाट्सएप पर वापस जाएं",
    linkText:"मैं स्वीप पावर रिवॉर्ड प्रोग्राम का हिस्सा बनना चाहूंगा",
    input_upi_id_text : "यूपीआई आईडी दर्ज करें",
    input_gpay_no_text : "GPay नंबर दर्ज करेंr" ,
    input_gpay_name_text : "GPay नाम दर्ज करें"  ,
    input_farmily_id : "फार्मली आईडी दर्ज करें" ,
    input_sfdc_code : "अपना SFDC कोड दर्ज करें।" ,

  },
 
};

export default translations;
