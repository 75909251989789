

import { Routes, Route } from "react-router-dom";
import UpiDetails from "./pages/UpiDetails.js";
import Thankyou from "./pages/Thankyou.js"
import DistributorUpiDetails from "./pages/DistributorUpiDetails.js";
import RetailerUpiDetails from "./pages/RetailerUpiDetails.js"
import DistributorThankyou from "./pages/DistributorThankyou.js";
import RetailerThankyou from "./pages/RetailerThankyou.js"
import FarmerEntryPoint from "./pages/FarmerEntryPoint.js"
import EarnCashback from "./pages/EarnCashback.js"
import Scan from "./pages/Scan.js"
import Awesome from "./pages/Awesome.js"
import Congratulation from "./pages/Congratulation.js"
import DistributorUpiDetailsWithSfdc from "./pages/DistributorUpiDetailsWithSfdc.js";
import RetailerUpiDetailsWithFirmilyId from "./pages/RetailerUpiDetailsWithFirmilyId.js"
import 'react-toastify/dist/ReactToastify.css';
import "./farmer.css";
import "./App.css";
import "./upiDetails.css"
function App() {
  return (
    <Routes>
    <Route path="/:language?" element={<FarmerEntryPoint />}></Route>
    <Route path="/earnCashback/:id/:language?" element={<EarnCashback />}></Route>
    <Route path="/scan/:id/:language?" element={<Scan />}></Route>
    <Route path="/awesome/:id/:language?" element={<Awesome />}></Route>
    <Route path="/upiDetails/:id/:language?" element={<UpiDetails />}></Route>
    <Route path="/congratulation/:language?" element={<Congratulation />}></Route>
    <Route path="/thankyou/:language?" element={<Thankyou />}></Route>
    {/* routes for distributor and retailer check with sfdc code and farmily id*/}
    <Route path="/distributor/upiDetails/:language?" element={<DistributorUpiDetailsWithSfdc />}></Route>
    <Route path="/retailer/upiDetails/:language?" element={<RetailerUpiDetailsWithFirmilyId />}></Route>
    {/* routes for distributor and retailer */}
    <Route path="/distributor/upiDetails/:id/:language?" element={<DistributorUpiDetails />}></Route>
    <Route path="/distributor/thankyou/:language?" element={<DistributorThankyou />}></Route>
    <Route path="/retailer/upiDetails/:id/:language?" element={<RetailerUpiDetails />}></Route>
    <Route path="/retailer/thankyou/:language?" element={<RetailerThankyou />}></Route>

  </Routes>
  );
}

export default App;
