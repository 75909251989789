import React from 'react';
import { useParams } from 'react-router-dom';
import commonHelper from '../helpers/commonHelper';
function FarmerEntryPoint() {
  const { language = "english" } = useParams();
  const translations = commonHelper.getTransOfLanguage(language);

  return (
  <main className="sweep-thnku farmer_landing">
    <section className="thnk-page">
        <img src="/images/farmer_bg1.png" className="img-fluid sweep-thnku-bg" alt=""/>
        <div className="welcome-content">
            <img src="/images/logo.png" className="img-fluid sweep-logo" alt=""/>
            <div className="sweep-thnku-text">
              {language && commonHelper.getTextByLanguage(language)}
                <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATS_APP_NUMBER}&text=${translations?.linkText}`}><img src="images/WhatsAppIcon.png" className="img-fluid wp_img" alt=""/></a>
            </div>
        </div>
    </section>
</main>
  )
}

export default FarmerEntryPoint