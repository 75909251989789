import React, { useRef, useEffect, useState } from 'react'
import jsQR from 'jsqr';
import APIServicenew from "../utils/APIGeneralService";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import commonHelper from '../helpers/commonHelper';
const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

function Scan() {
  const videoRef = useRef(null);
  const [result, setResult] = useState('');
  const [permissionRequested, setPermissionRequested] = useState(false);
  const apiCallInProgressRef = useRef(false); 
  const [showPopup, setShowPopup] = useState(false);
  const [uidNum, setUidNum] = useState("")
  // const [show, setShow] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id, language  } = useParams();
  const naviagte = useNavigate();
  let navigateTimeoutId ;


  const translations = commonHelper.getTransOfLanguage(language);


  useEffect(() => {
    if (!result) {
      const video = videoRef.current;
      // let navigateTimeoutId ;
      let stream = null;

      const startCamera = async () => {
        try {
          if (!permissionRequested) {
            const permission = await navigator.permissions.query({ name: 'camera' });
            if (permission.state === 'denied') {
              console.error('Camera access denied by user');
              return;
            }
            setPermissionRequested(true);
          }

          stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
          video.srcObject = stream;
          video.play();
          video.onloadedmetadata = () => {
            scanQRCode();
          };
        } catch (err) {
          console.error('Error accessing camera:', err);
        }
      };

      const scanQRCode = () => {
        try {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          

          const width = video.videoWidth;
          const height = video.videoHeight;

          canvas.width = width;
          canvas.height = height;

          context.drawImage(video, 0, 0, width, height);

          const imageData = context.getImageData(0, 0, width, height);
          const code = jsQR(imageData.data, width, height, {
            inversionAttempts: 'dontInvert',
          });
          console.log("code", typeof code?.data)

          if (code && typeof code.data == "string") {
            setResult(code.data);
            // setShowPopup(true);
            // navigateTimeoutId = setTimeout(() => {
            //   setShowPopup(false);
            // }, 5000);
            if (!apiCallInProgressRef.current) {
              apiCallInProgressRef.current = true;
              handleSubmit(code.data);
            }
          }

          requestAnimationFrame(scanQRCode);
        } catch (err) {
          console.error('Error scanQRCode ', err);
        }
      };

      const timeoutId = setTimeout(startCamera, 3000);

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(navigateTimeoutId)
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }
      };
    }
  }, [result]);

  const handleSubmit = async (qrCode = result) => {
    setLoading(true)
    let data = qrCode ? qrCode : uidNum ? uidNum : false
    let inputSource = qrCode ? "scanned" : uidNum ? "manual" : null
    if (typeof data == "string" && data) {
      try {
        const res = await APIServices.post(`farmer/validateScanData/${id}`, { data, inputSource });
        if (res.status === 200) {
          setShowPopup(true);
          navigateTimeoutId = setTimeout(() => {
            setShowPopup(false);
          }, 5000);
          if (res.data && res.data.status) {
            const { packSize, shipperSize, primaryPack } = res.data.data
            const data = {
              // shipperSize,
              primaryPack,
              navigateTopage:"/congratulation"
            };

            const encodedData = encodeURIComponent(JSON.stringify(data));

            if (inputSource == "manual") {
              setShowPopup(true);
              setFormSubmitted(true)
            }

            setTimeout(() => {
              if (inputSource == "manual") {
                setShowPopup(false);
                setFormSubmitted(false)
              }
              naviagte(`/awesome/${id}/${language}?data=${encodedData}`)
            }, 3000);
            setLoading(false)
          } else {
            toast.error(res?.data?.message || 'Something went wrong, please try after sometime.', {
              className: 'dark-toast'
            });
            setResult('')
            setLoading(false)
          }
        } else {
          toast.error(res?.data?.message || 'Something went wrong, please try after sometime.');
          setResult('')
          setLoading(false)
        }
        
      } catch (error) {
        console.log("error in catch handleSubmit ", error);
        toast.error(error?.message || 'Something went wrong, please try after sometime.');
        setLoading(false)
      }
    } else {
      toast.error('No QR code found. Please align your camera with the QR code Or enter the UID.');
      setLoading(false)
    }


  }

  const handleInput = async (value) => {
    if (result) {
      setResult('')
    }
    setUidNum(value)
  }


  return (
    <>
      <main class="sweep-thnku farmer_landing fl_scan_screen">
        <ToastContainer theme="colored" />
        <section class="thnk-page">
          <img src="/images/farmer_bg3.png" class="sweep-thnku-bg" />
          <div class="welcome-content">
            <div className="video-container">
              <video ref={videoRef} className="video-frame" />
              {/* <div className="qr-frame"> */}
              <img src='/images/qr.svg' alt="SVG Image" className="qr-frame" />
            </div> {/* Frame for QR code */}
            {/* </div> */}
            {/* <video ref={videoRef} style={{ width: '90%' }} /> */}
            {/* <p>{result}</p> */}
            <p class="scan-upi-para">{translations?.scan_or}</p>
            <input type="text" value={uidNum} onChange={(e) => handleInput(e.target.value)} placeholder={translations?.scan_uid} className='form-control' />
            <br />
            {/* <div> */}
            <button className="btn btn-success sweep-submit" onClick={() => handleSubmit()} disabled={loading}>{loading ? translations?.scan_loading : translations?.scan_submit}</button>
            {/* </div> */}
          </div>
        </section>
        <Offcanvas show={showPopup} placement="bottom" className="scan_success" id="scan_success">
          <Offcanvas.Body className="p-0 " >
            <img src="/images/canvas_bg.png" className="img-fluid canvas_bg" style={{ width: "100%" }} />
            <div className="cs_success_inner">
              <img src="/images/right_icon.png" className="img-fluid right_icon" />
              {formSubmitted ? <h4 className="middle_heading">{translations?.scan_submit_succ}</h4> :
                <h4 className="middle_heading">{translations?.scan_succ}</h4>}
            </div>
          </Offcanvas.Body>
        </Offcanvas>

      </main>
    </>
  )
}

export default Scan