import translations from './translations.js';

const commonHelper = {
    getTextByLanguage: function (language) {
        switch (language) {
            case "english":
                return <h1 className="big_font">Start Your<br />SWEEP POWER<br />Reward Journey <br />Today !</h1>
            case "punjabi":
                return <h1 className="big_font">ਸੁਰੂ ਕਰੋ<br />ਆਪਣੀ ਸਵੀਪ ਪਾਵਰ<br />ਇਨਾਮ ਯਾਤਰਾ <br />ਅੱਜ ਤੋਂ !</h1>
            case "marathi":
                return <h1 className="big_font">तुमचा <br />स्वीप पॉवर रीवॉर्ड <br />प्रवास आजच सुरु करा !</h1>
            case "bengali":
                return <h1 className="big_font">শুরু করুন আপনার<br /> সুইপ পাওয়ার <br />রিওয়ার্ড অভিযান আজই !</h1>
            case "gujrati":
                return <h1 className="big_font">આજે જ તમારી<br /> સ્વીપ પાવર પુરસ્કાર<br /> યાત્રા શરૂ કરો !</h1>
            case "tamil":
                return <h1 className="big_font">உங்கள் ஸ்வீப் பவர் <br />வெகுமதி பயணத்தை <br />இன்றே தொடங்குங்கள் !</h1>
            case "kannada":
                return <h1 className="big_font">ಇಂದೇ ನಿಮ್ಮ <br />ಸ್ವೀಪ್ ಪವರ್ ರಿವಾರ್ಡ್<br /> ಪ್ರಯಾಣ ಆರಂಭಿಸಿ !</h1>
            case "telugu":
                return <h1 className="big_font">నేడే మీ<br /> స్వీప్‌ పవర్‌ రివార్డు<br /> ప్రయాణాన్ని ప్రారంభించండి !</h1>

            case "hindi":
                // return <h1 className="big_font">अपनी<br />स्वीप पावर रिवॉर्ड जर्नी <br />शुरू करें
                // <br /> आज !</h1>     
                  return <h1 className="big_font">अपनी<br />स्वीप पावर रिवॉर्ड यात्रा<br />आज ही शुरू करें  !</h1>

            default:
                return <h1 className="big_font">Start Your<br />SWEEP POWER<br />Reward Journey <br />Today !</h1>
        }
    },
    getTransOfLanguage: function (language) {
        const langObj = translations[language] || translations.english;
        return langObj; 
      }
}


export default commonHelper;