import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import commonHelper from '../helpers/commonHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import CongratulationContent from './CongratulationContent';

function Congratulation() {
  const priceMoney = 5;
  const location = useLocation();
  const naviagte = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const encodedData = searchParams.get('data');
  const { language } = useParams();


  // Decode the encoded data
  const decodedData = encodedData ? JSON.parse(decodeURIComponent(encodedData)) : {};
  // const { shipperSize } =  decodedData
  const { primaryPack } = decodedData



  const cashBack = primaryPack * priceMoney;
  const translations = commonHelper.getTransOfLanguage(language);

  return (
    <main class="sweep-thnku farmer_landing farmer_congratulation">
      <section class="thnk-page">
        <img src="/images/farmer_bg3.png" class="sweep-thnku-bg" alt="" />
        <div class="welcome-content">
          <img src={translations?.congratu_img} class="img-fluid sweep-logo" alt="" />
          {/* <div class="sweep-gpay">
                <p class="sweep-upi-para">{`${translations?.congratu_head} ${cashBack} ${translations?.congratu_head_sec}`}</p>
               <p>{`${primaryPack && primaryPack +" " + translations?.congratu_head_sub }`}</p>                    
            </div> */}
          {translations && cashBack && primaryPack &&
            <CongratulationContent
              translations={translations}
              cashBack={cashBack}
              primaryPack={primaryPack}
              language={language}
            />}
          <div className="sweep-thnku-text">
            <a className='whatsapp-button' href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATS_APP_NUMBER}`}>
              {/* <i className="fab fa-whatsapp whatsapp-icon"></i> */}
              <FontAwesomeIcon className="fab fa-whatsapp whatsapp-icon" icon={faWhatsapp} />
            </a>
          </div>
        </div>
        <img src={translations?.congratu_cashback_img} class="img-fluid cashback_img" alt="" />
      </section>
    </main>
  )
}

export default Congratulation