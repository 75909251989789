import React from 'react'

function CongratulationContent({ translations, cashBack, primaryPack, language }) {
    switch (language) {
        case "english":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${cashBack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${primaryPack && primaryPack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        case "punjabi":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        case "tamil":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${primaryPack && primaryPack} ${translations?.congratu_head} `}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        case "telugu":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        case "marathi":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        case "gujrati":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        case "bengali":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )
        case "kannada":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )
        case "hindi":
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${primaryPack && primaryPack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`₹ ${cashBack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )

        default:
            return (
                <div class="sweep-gpay">
                    <p class="sweep-upi-para">{`${translations?.congratu_head} ${cashBack} ${translations?.congratu_head_sec}`}</p>
                    <p class="sweep-upi-subtext">{`${primaryPack && primaryPack + " " + translations?.congratu_head_sub}`}</p>
                </div>
            )
    }

}

export default CongratulationContent